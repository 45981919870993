/*!

 =========================================================
 * Paper Kit 2 Pro Angular - v1.4.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/kit-2-pro-angular
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'kit/variables';
@import 'kit/mixins';

// Plugins CSS
@import "kit/plugins/plugin-bootstrap-switch";
@import "kit/plugins/plugin-bootstrap-select";
@import "kit/plugins/plugin-tagsinput";
@import "kit/plugins/plugin-fileupload";
@import "kit/plugins/plugin-datetimepicker";

// Core CSS
@import "kit/nucleo-outline";
@import "kit/alerts";
@import "kit/checkbox-radio";
@import "kit/badges";
@import "kit/pagination";
@import "kit/typography";
@import "kit/misc";
@import "kit/misc-extended";
@import "kit/buttons";
@import "kit/social-buttons";
@import "kit/inputs";
@import "kit/progress-bars";
@import "kit/tooltips-and-popovers";

// components
@import "kit/tabs-navs";
@import "kit/navbars";
@import "kit/navbar-extend";
@import "kit/dropdown-extend";
@import "kit/images";
@import "kit/tags";
@import "kit/animated-buttons";
@import "kit/preloaders";
@import "kit/tables";
@import "kit/info-areas";
@import "kit/media";
@import "kit/modal";
@import "kit/carousel";
@import "kit/footers";
@import "kit/headers";
@import "kit/collapse";

// cards
@import "kit/cards";
@import "kit/cards/card-background";
@import "kit/cards/card-blog";
@import "kit/cards/card-contact";
@import "kit/cards/card-image";
@import "kit/cards/card-register";
@import "kit/cards/card-plain";
@import "kit/cards/card-pricing";
@import "kit/cards/card-profile";
@import "kit/cards/card-product";
@import "kit/cards/card-signup";
@import "kit/cards/card-testimonials";

// For gallery, using PhotoSwipe
@import "kit/plugins/photo-swipe/main.scss";
@import "kit/plugins/photo-swipe/default-skin.scss";
@import "kit/plugins/photo-swipe/gallery.scss";
@import "kit/plugins/photo-swipe/main.scss";

// Isometric cards plugin
@import "kit/plugins/plugin-isometricGrids";

// example pages and sections
@import "kit/examples";
@import "kit/sections";
@import "kit/sections-extend";

@import "kit/responsive";

// Nucleo Icons
@import "kit/nucleo-icons";

// Angular Differences
@import "kit/angular-differences/presentation";
@import "kit/angular-differences/tags";
@import "kit/angular-differences/angular2-multiselect-dropdown";
@import "kit/angular-differences/dropdowns";
@import "kit/angular-differences/tabs-navs";
@import "kit/angular-differences/tooltips-and-popovers";
@import "kit/angular-differences/modal";
@import "kit/angular-differences/ngb-accordion";
@import "kit/angular-differences/ngx-gallery";
@import "kit/angular-differences/popovers";
@import "kit/angular-differences/ngb-datepicker";
@import "kit/angular-differences/sliders";
@import "kit/angular-differences/responsive";

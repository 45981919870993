@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css');
// /* Fuentes */
@font-face {
    font-family: 'Gotham Extra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham Extra Light'), url('/assets/fonts/Gotham-Light.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham Book'), url('/assets/fonts/Gotham-Book.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham Medium'), url('/assets/fonts/Gotham-Medium.otf') format('otf');
}

@font-face {
    font-family: 'Gotham Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham Bold'), url('/assets/fonts/Gotham-Bold.woff') format('woff');
}

.nav-tabs-wrapper{
    display: inline-block;
    margin-bottom: -6px;
    width: 100%;

    .vertical-tabs{
        .nav-tabs{
            border-bottom: none !important;
            &.flex-column{
                width: 33%;
                float: left;
                border-right: 1px solid #F1EAE0;
                font-size: 16px;
                font-weight: 600;
                padding: 20px 0;

                .nav-link.active:before{
                    border-right: 11px solid #F1EAE0;
                    border-top: 11px solid transparent;
                    border-bottom: 11px solid transparent;
                    content: "";
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    bottom: 22px;
                }
                .nav-link.active:after{
                    border-right: 11px solid #FFFFFF;
                    border-top: 11px solid transparent;
                    border-bottom: 11px solid transparent;
                    content: "";
                    display: inline-block;
                    position: absolute;
                    right: -1px;
                    bottom: 22px;
                }
            }
        }
        .tab-content{
          width: 63%;
          float: left;
          margin-left: 5px;
        }
    }
}
.nav-tabs {
  border-bottom: 1px solid #F1EAE0;
  margin-bottom: 30px;
  margin-left: 1.25%;
  margin-right: 1.25%;
}
.nav-tabs .nav-item .nav-link.active:before, .nav-tabs .nav-item .nav-link.active:hover:before, .nav-tabs .nav-item .nav-link.active:focus:before{
  bottom: 1px;
}
.nav-tabs .nav-item .nav-link.active:after, .nav-tabs .nav-item .nav-link.active:hover:after, .nav-tabs .nav-item .nav-link.active:focus:after{
  bottom: 0px;
}

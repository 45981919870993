@import './variables';

footer#idb_footer .container {
  max-width: 2000px !important;
}

footer#idb_footer {
  max-width: 2000px;
  width: 100%;
}


header#idb_header #block-mainmenu,
header#idb_header #block-mainmenues,
header#idb_header #block-mainmenufr,
header#idb_header #block-mainmenupt {
  width: 80%;
}

header#idb_header .container {
  width: 100% !important;
  max-width: 1215px;
}

header#idb_header #block-mainmenu ul.menu li a,
header#idb_header #block-mainmenues ul.menu li a,
header#idb_header #block-mainmenufr ul.menu li a,
header#idb_header #block-mainmenupt ul.menu li a {

  font-size: 15.5px;
  padding: 30px 5px 1px;
}



body {
  @media (min-width: 990px) {
    margin-top: 0px !important;
  }
}

header#idb_header {
  @media all and (max-width: 992px) {
    display: none !important;
  }
}

/* You can add global styles to this file, and also import other style files */
// @import "https://live-idb-config.pantheonsite.io/themes/custom/iadb/css/iadb-styles.css?v=0.0.4";
//Owl Carousel
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "rfs/scss";
@import "buttons";
@import "fonts";
@import "general";
@import "variables";
@import "reutilizables";
@import 'aos/dist/aos.css';
/* Importing Bootstrap SCSS file. */

// Size
$sizes0: 0;
$sizes5: 5px;
$sizes10: 10px;
$sizes12:12px;
$sizes15: 15px;
$sizes18: 18px;
$sizes19: 19px;
$sizes20: 20px;
$sizes21: 21px;
$sizes22: 22px;
$sizes25: 25px;
$sizes30: 30px;
$sizes40: 40px;
$sizes45: 45px;
$sizes50: 50px;
$sizes55: 55px;
$sizes60: 60px;
$sizes70: 70px;
$sizes80: 80px;
$sizes90: 90px;
$sizes100: 100px;

// Fonts
$ft8: 0.5rem;
$ft9: 0.5625rem;
$ft10: 0.625rem;
$ft11: 0.6875rem;
$ft12: 0.75rem;
$ft13: 0.8125rem;
$ft14: 0.875rem;
$ft15: 0.9375rem;
$ft16: 1rem;
$ft18: 1.125rem;
$ft20: 1.25rem;
$ft22: 1.375rem;
$ft24: 1.5rem;
$ft25: 1.5625rem;
$ft26: 1.625rem;
$ft28: 1.75rem;
$ft30: 1.875rem;
$ft32: 2rem;
$ft34: 2.125rem;
$ft36: 2.25rem;
$ft38: 2.375rem;
$ft40: 2.5rem;

// Weight
$thin: 100;
$light: 300;
$regular: 400;
$semi: 500;
$regular-bl: 600;
$bold: 900;
$decoration-no: none;

//Position text
$text-alig-end: end;

// Fonts
$gotham-light: 'Gotham Extra Light', sans-serif, Arial;
$gotham: 'Gotham Book', sans-serif, Arial;
$gotham-medium: 'Gotham Medium', sans-serif, Arial;
$gotham-bold: 'Gotham Bold', sans-serif, Arial;

// Colors
$primary-color:#8ABE26;
$color-text: #858383;
$blue-color-title: #476D9F;
$white:#FFFFFF;
$primary-color-side: #4E933F;


$color-menu:#FFFFFFA6;
$alliance-color: #DE4769;
// $ecosystems-color: #FDD803;
$ecosystems-color: #FFCC00;
$entrepreneurship-color: #00715D;
$l-store-color:#5CA9DD;
$use-cases-color:#F58820;
$resources-color: #74C2BF;
$the-blockchain-color:#2F66B0;
$footer-color: #F8F8F8;
$background-sections-color: #F8F8F8;
$gray:#DDDDDD;
$text-search: #A8A8A8;


// Body Layout
$layout-background-color:$white;
$layout-border-radius: 8px;
$layout-margin-top: 2rem;
$layout-margin-bottom: 2rem;
$layout-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.27);
$height-auto: auto;
$display-block: block;
$margin-left-auto:auto;
$margin-right-auto:auto;
$min-height: 85vh;
$width-100: 100%;
$max-width: 1220px;

html {
  height: 100%;
  font-size: 100%;
  // overflow-x: hidden !important;
}

a,
span {
  font-family: $gotham;
  text-decoration: none !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1rem);
}

p {
  font-family: $gotham !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1.1rem);
  color: $color-text;
}

h1 {
  font-family: $gotham-bold;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(2rem);
  text-transform: uppercase;
}

h2 {
  font-family: $gotham-bold;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1.4rem);
  text-transform: uppercase;
}

h3 {
  font-family: $gotham-medium;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1.1rem);
}

.feature {
  fill: #ccc;
  cursor: pointer;
}

.feature.active {
  fill: #4d66b3 !important;
}

h4 {
  font-family: $gotham-medium;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1rem);
}

body {
  overflow-x: hidden !important;
  background-color: $white;
  position: relative;
  background-size: cover;
  height: 99vh;
  background-attachment: fixed;
}

strong,
b {
  font-family: $gotham-medium !important;
  font-weight: 600 !important;
}

// Style to quit black in maps
.mesh {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
}

// Styles Banner Header

.banner-home {
  margin-top: -130px;

  .page-header {
    min-height: 78vh;
    max-height: 750px;
    height: 100%;

    .filter::after {
      background-color: rgb(0 0 0 / 0%);
    }
  }

  .title-brand {
    position: absolute;
    left: 55px;
    bottom: -220px;

    @media(max-width: 767px) {
      left: 0;
    }

    .banner-title {
      color: $primary-color;
      font-family: $gotham-bold;
      font-size: 2.5rem;
    }
  }
}

.section-one-tex {
  .section-tex {
    padding: 3rem 3rem;

    p {
      font-size: 1.1rem;
      text-align: justify;
      font-family: $gotham-medium !important;
    }
  }
}

ul.ul-style {
  padding-inline-start: 12px;

  li {
    list-style: none;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: -15px;
      top: 10px;
      width: 5px;
      height: 5px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      background-color: $color-text;
    }

    span {
      font-size: 1.1rem;
      color: $color-text;
    }
  }
}


//Scroll
::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #a1a09f;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

//Scroll

.back-gray {
  background-color: #F8F8F8;
}

.back-green {
  background-color: $primary-color;
}

.color-primary {
  color: $primary-color;
}

// Styles search

.search {
  width: 100%;
  position: relative;
  display: block;

  @media(max-width: 1000px) {
    width: 100%;
  }
}

.searchTerm {
  width: 100%;
  border-radius: 8px 0 0 8px;
  color: #5A5A5A;
  height: 48px;
  font-family: $gotham;
  padding-left: 1rem;
}

.searchButton {
  font-family: $gotham-bold;
  color: $white;
  font-size: $sizes18;
  width: 170px;
  height: 48px;
  text-align: center;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
  transition: all .3s ease-in-out;

  @media(max-width: 900px) {
    font-size: 16px;
  }
}

.searchButton:hover {
  opacity: 0.8;
}

.style-content-not-found {
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
  margin: 0 auto;
  box-shadow: 0px 0px 8px #00000029;
  font-weight: 500;
  border: 2px solid #dfdddd;
  background: $white;
  margin-bottom: 3rem;
}

.iframe-container iframe {
  border-radius: 10px;
  height: 280px;

  @media(max-height: 600px) {
    height: 200px;
  }
}

.title-green {
  margin: 15px 0 0;
  color: $primary-color;
  font-family: $gotham-medium;
  font-weight: 700;
  margin-top: 0;
  font-size: 2rem;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 2.1em;
  }
}

.title-blue {
  margin: 15px 0 0;
  color: $blue-color-title;
  font-family: $gotham-medium;
  font-weight: 700;
  margin-top: 0;
  font-size: 2rem;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 2.1em;
  }
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $primary-color;
}

.owl-theme .owl-dots .owl-dot span {
  width: 17px;
  height: 17px;
  border: 2px solid $primary-color;
  background-color: transparent;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 0px;

  @media(max-width: 767px) {
    margin-top: 20px;
  }
}


//Estilos de cards en boletines
.bulletin .owl-theme .owl-nav.disabled+.owl-dots {
  @media (max-width: 900px) {
    text-align: center;
  }
}

.bulletin .owl-theme .owl-dots .owl-dot.active span,
.bulletin .owl-theme .owl-dots .owl-dot:hover span {
  background-color: $blue-color-title !important;
}

.bulletin .owl-theme .owl-dots .owl-dot span {
  width: 17px;
  height: 17px;
  border: 2px solid $blue-color-title !important;
  background-color: transparent;
}

.bulletin .owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 10px;
}

.card .card-body {
  padding: 20px;
  padding-bottom: 0px;
}

.container-project {
  max-width: 1475px;
  width: 100%;
  margin: auto;
  padding: 0rem 3rem;

  @media (max-width: 992px) {
    padding: 2rem 0rem;
  }
}

// Cards projects styles Caribbean-Latin America-Projects

.card-project-container {
  max-width: 340px;
  width: 100%;
  margin: auto;

  .card {
    max-width: 375px;
    max-height: 400px;
    min-height: 400px;
    margin-top: 12px;

    .p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .flag {
    img {
      max-width: 30px;
      max-height: 30px;
      margin-left: auto;
    }
  }

  .ellipsis-card-project {
    width: 100%;
    height: 70px;
    margin: 1rem 0 1.3rem
  }

  a {
    font-weight: 700;
  }
}

.content-map {
  position: relative;

  .col-12.col-md-6 {
    @media (max-width: 992px) {
      height: 450px !important;
    }

    @media (max-width: 450px) {
      height: 410px !important;
    }

    @media (max-width: 400px) {
      height: 380px !important;
    }

    #mapInitiatives svg {
      width: 100% !important;
    }

    #mapInitiativesS svg {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.contain-bar-white {
  z-index: 9999 !important;
}

h5 .color-bold {
  color: #9be327;
}

.section-two-tex ul li {
  font-family: "Gotham Book", sans-serif, Arial !important;
  color: #7b7c80;
  font-size: 16px;
}

.bold {
  color: $primary-color !important;
  font-size: 20px;
}

.search {
  margin-bottom: 3rem;

  .fa {
    font-size: 27px;
    padding-right: 2rem;
  }

  .searchTerm {
    width: 70%;
    border: 1px solid $primary-color;
    border-radius: 50px;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
  }

  @media (max-width: 767px) {
    .m-mobile {
      padding-top: 20px;
    }
  }

  @media (max-width: 530px) {
    .searchTerm {
      width: 75%;
    }
  }

  .searchTerm:focus,
  .searchButton:focus {
    outline: $primary-color solid 0px;
  }

  .searchButton {
    border: 1px solid $primary-color;
    background: $primary-color;
    max-width: 50px;
    border-radius: 50px;
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
  }

  .title-sections,
  .text-search-advanced {
    color: $primary-color;
  }

  .button-section {
    background-color: $primary-color;
  }

  @media (max-width: 1024px) {
    .dropdown-advanced-search {
      padding: 1rem;
    }
  }

  .display-cont {
    display: contents;
  }

  label {
    color: $primary-color;
    font-weight: bold;
  }

  .margin-desktop {
    margin-top: 15px;
  }
}


.suscripcion {
  width: 100%;
  background: #89b022;
  //background: $primary-color;

  &__content {
    padding: 1.5rem 2rem;

    &--title {
      color: #FFFFFF;
      font-family: "Gotham Medium", sans-serif, Arial !important;
      font-size: 2rem;
    }

    &--input {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      transition: background-color 0.3s ease 0s;
      box-shadow: none;
      width: 75%;
      margin-top: 0px;
    }

    @media(max-width: 767px) {
        &--title {
          font-size: 1.8em;
        }
      }
    }

    &--icon {
      position: relative;
      top: 8px;
      left: 15px;
      font-size: 22px;
    }

    &--button {
      width: 25%;
      background-color: #b9e459;
      border: 1px solid #b9e459;
      color: #FFFFFF;
      font-family: "Gotham Book", sans-serif, Arial;
      font-size: 16px;
      height: 40px;
      padding: 0;
      line-height: 0;
      text-transform: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      &:hover {
        background: #b9e459;
        color: #ffffff;
        opacity: 0.8;
        border: 1px solid #b9e459;
      }

    }

    @media(max-width:400px) {
      &--button {
        font-size: 14px;
      }

      .pd-x {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &--input {
      border: 1px solid rgba(255, 255, 255, 0);
      color: #000000;
      margin-top: -1px;
      padding-left: 20px;
      font-size: 18px;
      font-weight: 600;
      opacity: 0.4;
      height: 42px;
      padding-left: 60px;
      border-top-left-radius: 25px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 25px;

      @media(max-width: 767px) {
        font-size: 13px;
      }
    }

    &--input:focus {
      border: 1px solid rgba(255, 255, 255, 0);
    }
  }

  .img-pais {
    max-width: 30px;
    max-height: 30px;
  }

  .typeProject {
    color: $primary-color;
  }

  .name-pais {
    padding-left: 10px;
    margin-top: 3px;
  }

  .img-sobre {
    width: 36px;
    height: 22px;
    position: absolute;
    margin-top: 7px;
    margin-left: 20px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .card-icon-projects {
    display: block;
    margin: 0 auto;
    position: relative;
    text-align: center;
    padding: 15px 0 10px;
  }

  .modal-body-ecomicro{
    padding: 20px 50px;
    color: #000;

    @media(max-width: 767px){
      padding: 20px 20px;
    }
  }
